<script setup lang="ts">
const { step } = useCheckoutStep()
</script>

<template>
  <div class="flex flex-col w-full pt-20 h-screen">
    <Header is-checkout />

    <CheckoutTitle />

    <main :class="step.showTitle ? 'mt-[89px]' : ''" class="flex-1">
      <slot />
    </main>

    <Footer is-checkout />
  </div>
</template>
